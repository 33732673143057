/** @jsx jsx */
import { jsx } from 'theme-ui'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import InputBase from '@material-ui/core/InputBase'
import EndAdornment from '../EndAdornment'
import { isNil } from 'ramda'

import * as style from './style'
import { FormattedNumberInput } from '../FormattedNumber'


const getValue = val => {
  if (!val) {
    return ''
  } else if (typeof val === 'string' || val instanceof String) {
    const strVal = val.replaceAll(' ', '')
    return +strVal
  } else {
    return val
  }
}

const AmountSelector = ({
  updateFn,
  tabIndex,
  spotIndex,
  styles,
  endAdornmentText,
  maxLength = '15',
  value = 1,
}) => {
  const endAdornment = endAdornmentText && <EndAdornment children={endAdornmentText} />
  return (
    <div sx={{ ...style.selector, ...styles }}>
      <InputBase
        inputProps={{
          maxLength: maxLength,
        }}
        endAdornment={endAdornment}
        sx={{ ...style.input, ...styles }}
        value={getValue(value)}
        inputComponent={FormattedNumberInput}
        name="amount-selector"
        id="amount-selector-input"
        onChange={e =>
          updateFn({
            tabIndex,
            index: spotIndex,
            amount: e.target.value ? +e.target.value : '',
            value: e.target.value ? +e.target.value : '',
          })
        }
        onBlur={e =>
          updateFn({
            tabIndex,
            index: spotIndex,
            amount: e.target.value === '' ? 1 : e.target.value,
            value: e.target.value === '' ? 1 : e.target.value,
          })
        }
      />
      <div sx={{ ...style.container, height: '100%' }}>
        <button
          sx={{ ...style.price, width: '46.5%', minWidth: '25px' }}
          disabled={isNil(value) || value <= 1}
          onClick={() =>
            updateFn({
              tabIndex,
              index: spotIndex,
              amount: getValue(value) > 1 ? getValue(value) - 1 : getValue(value),
              value: getValue(value) > 1 ? getValue(value) - 1 : getValue(value),
            })
          }
        >
          <ArrowDropDown sx={{ marginLeft: '-1px' }} {...styles} />
        </button>
        <button
          sx={{ ...style.price, width: '46.5%', minWidth: '25px' }}
          disabled={isNil(value)}
          onClick={() =>
            updateFn({
              tabIndex,
              index: spotIndex,
              amount: getValue(value) + 1,
              value: getValue(value) + 1,
            })
          }
        >
          <ArrowDropUp sx={{ marginLeft: '-1px' }} {...styles} />
        </button>
      </div>
    </div>
  )
}

export default AmountSelector
