/** @jsx jsx */
import React, { memo, useContext } from 'react'
import { jsx } from 'theme-ui'
import * as style from './style'
import { connect } from 'react-redux'
import { I18nContext } from '../../containers/i18n'
import { PermissionsContext } from '../../containers/PermissionsProvider'
import { makeOrderWithConfirm, makeOrder } from '../../actions/orders'
import { showErrorDialog } from '../../actions/workspace'
import { shortenNumber } from '../../common/utilities/formatNumber'
import { createSelector } from 'reselect'
import { confirmDialogSelector, currentWorkspaceSelector } from '../../selectors/workspace'
import { accountsSelector } from '../../selectors/accounts'
import { sendOrder } from '../../common/utilities/orderUtils'

const dataPlaceHolder = '-'

const MarketDepthPanel = ({
  instrument,
  instrumentDepthData,
  depth,
  spotIndex,
  tabIndex,
  keycloak,
  isConfirmDialogRequired,
  currentWorkspace,
  currentAccount,
  makeOrderWithConfirm,
  makeOrder,
  showErrorDialog,
}) => {
  const { t } = useContext(I18nContext)
  const { hasAnyRole } = useContext(PermissionsContext)

  const handleModal = message =>
    showErrorDialog({
      title: t('error').toUpperCase(),
      type: 'ERROR',
      message: message,
    })

  const make = payload => {

    sendOrder({
      payload,
      currentAccount,
      instrument,
      hasAnyRole,
      t,
      isConfirmDialogRequired,
      makeOrderWithConfirm,
      makeOrder,
      keycloak,
      handleModal,
    })
  }

  function makeSell(bid) {
    bid && makeTrade('SELL', bid)
  }

  function makeBuy(ask) {
    ask && makeTrade('BUY', ask)
  }

  function makeTrade(direction, priceData) {
    const { amountToOperate, clientId, decisorId, executorId, tradingCapacity } = currentWorkspace.tabs[tabIndex].spots[spotIndex]

    make({
      side: direction,
      price: priceData,
      orderType: 'LIMIT',
      quantity: amountToOperate,
      clientId,
      decisorId,
      executorId,
      tradingCapacity,
      title: t('confirmOrder'),
    })
  }

  const buildDepth = () => {
    const marketDepth = []
    for (let i = 0; i < depth; i++) {
      const instrumentData = instrumentDepthData[i] || undefined
      const bid = instrumentData && instrumentData.bidPrice !== '0' && instrumentData.bidPrice
      const ask = instrumentData && instrumentData.askPrice !== '0' && instrumentData.askPrice
      marketDepth.push(
        <div key={`${instrument?.name}${i}`} sx={style.depthSection}>
          <div sx={style.setBoxLeftStyle}>
            {instrumentData && instrumentData.bidVolume !== '0' && instrumentData.bidVolume
              ? shortenNumber(instrumentData.bidVolume, 1)
              : dataPlaceHolder}
          </div>
          <div
            sx={style.setButtonStyle()}
            onClick={() => {
              makeSell(bid)
            }}
          >
            {bid || dataPlaceHolder}
          </div>
          <div
            sx={style.setButtonStyle(true)}
            onClick={() => {
              makeBuy(ask)
            }}
          >
            {ask || dataPlaceHolder}
          </div>
          <div sx={style.setBoxRightStyle}>
            {instrumentData && instrumentData.askVolume !== '0' && instrumentData.askVolume
              ? shortenNumber(instrumentData.askVolume, 1)
              : dataPlaceHolder}
          </div>
        </div>
      )
    }
    return marketDepth
  }

  return (
    <div className="container" sx={style.depthPanel}>
      <div sx={style.depthHeader}>
        <div sx={style.setLabelVolLeftStyle}>{t('vol')}</div>
        <div sx={style.setLabelPriceStyle}>{t('bid')}</div>
        <div sx={style.setLabelPriceStyle}>{t('ask')}</div>
        <div sx={style.setLabelVolRightStyle}>{t('vol')}</div>
      </div>
      {buildDepth()}
    </div>
  )
}


const mapStateToProps = (state, ownProps) =>
  createSelector(
    currentWorkspaceSelector,
    confirmDialogSelector,
    accountsSelector,
    (
      currentWorkspace,
      isConfirmDialogRequired,
      currentAccount
    ) => ({
      currentWorkspace,
      isConfirmDialogRequired,
      currentAccount
    })
  )(state, ownProps)

const mapDispatchToProps = {
  makeOrderWithConfirm,
  makeOrder,
  showErrorDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(MarketDepthPanel))
