
import { fmtDateyyyMMdd, orkValidate } from '../../common/utilities'


export const sendOrder = ({
    payload,
    currentAccount,
    instrument,
    hasAnyRole,
    t,
    isConfirmDialogRequired,
    makeOrderWithConfirm,
    makeOrder,
    keycloak,
    handleModal,
}) => {
    const selectedAccount = currentAccount[instrument.exchangeId]
    const needsOrk = instrument.marketHasOrk && hasAnyRole(['member_trader'])

    if (selectedAccount && selectedAccount.account) {
        const trading = {
            clientId: payload.clientId,
            decisorId: payload.decisorId,
            executorId: payload.executorId,
            tradingCapacity: payload.tradingCapacity,
            securityType: instrument.securityType,
        }
        const tif = payload.tif || instrument?.allowedTimeInForces[payload.orderType][0]
        const info = {
            symbol: instrument.symbol,
            exchangeId: instrument.exchangeId,
            side: payload.side,
            orderType: payload.orderType,
            //TODO: Corregir
            quantity: isNaN(payload.quantity) ? payload.quantity?.replaceAll(' ', '') : payload.quantity,
            tif,
            operationAccount: selectedAccount.account,
            ...(['LIMIT', 'STOP_LIMIT'].includes(payload.orderType) && { price: payload.price }),
            ...(['STOP_LIMIT'].includes(payload.orderType) && { stopPrice: payload.stopPrice || 0 }),
            ...(['GTD'].includes(tif) && payload.expirationDate && { expirationDate: fmtDateyyyMMdd(payload.expirationDate) }),
        }
        const infoData = needsOrk ? { ...info, ...trading } : { ...info }
        const action = info.side == 'BUY' ? 'BUYACTION' : 'SELLACTION'
        const message = `${t(action).toUpperCase()} ${payload.quantity} ${info.symbol}${info.orderType !== 'MARKET' ? ` @ ${info.price} ` : ` `}${t(info.orderType).toUpperCase()} ${t(info.tif).toUpperCase()}`
        if (!needsOrk || orkValidate({ ...trading }).isValid) {
            isConfirmDialogRequired
                ? makeOrderWithConfirm(
                    infoData,
                    { body: message, title: t('confirmOrder'), selectedAccount },
                    keycloak.token
                )
                : makeOrder(infoData, keycloak.token)
        } else {
            handleModal(t('orkError').toUpperCase())
        }
    } else {
        handleModal(t('accountError'))
    }
}
