/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import { connect } from 'react-redux'
import React, { useState, useEffect, useContext } from 'react'
import { ClickAwayListener, InputAdornment } from '@material-ui/core'

import * as style from './style'
import { setPos } from '../NewSpot/style'
import CustomButton from '../CustomButton'
import PriceSelector from '../PriceSelector'
import AmountSelector from '../AmountSelector'
import InstrumentSearch from '../InstrumentSearch'
import { I18nContext } from '../../containers/i18n'
import { renderPips } from '../../common/utilities'
import { editTradePanel, updateTradePanel, showErrorDialog } from '../../actions/workspace'
import { subscribeSymbolDynamic, unsubscribeSymbolDynamic } from '../../actions/marketData'
import { makeOrderWithConfirm, makeOrder, connectToOrdersSocket } from '../../actions/orders'
import { PermissionsContext } from '../../containers/PermissionsProvider'
import PermissionsVisible from '../../containers/PermissionsProvider/PermissionsVisible'
import TradingCapacity from './tradingCapacity'
import Orders from './orders'
import FormattedNumberText from '../FormattedNumber'
import { tradeSelector, tickSelector } from '../../selectors/marketData'
import {
  confirmDialogSelector,
  currentDataSelector,
  currentWorkspaceSelector,
} from '../../selectors/workspace'
import { hydratedInstrumentSelector } from '../../selectors/instruments'
import { createSelector } from 'reselect'
import { accountsSelector } from '../../selectors/accounts'
import { sendOrder } from '../../common/utilities/orderUtils'

const AMOUNT_VALUES = [1, 2, 5, 10, 20]

const BUY = 'BUY'
const BUYACTION = 'BUYACTION'
const SELL = 'SELL'
const SELLACTION = 'SELLACTION'

const TradePanel = ({
  tabIndex,
  editTradePanel,
  data = {},
  updateTradePanel,
  subscribeSymbolDynamic,
  unsubscribeSymbolDynamic,
  makeOrder,
  makeOrderWithConfirm,
  isConfirmDialogRequired,
  keycloak,
  showErrorDialog,
  marketData,
  instrument,
  currentAccount,
}) => {
  const [priceInputsDisabled, setPriceInputsDisabled] = useState(false) // The user can't edit prices (market orders)
  const [limitPriceWasEdited, setLimitPriceWasEdited] = useState(false) // The user has edited the limit price and we don't want to change it's value automatically
  const [stopPriceWasEdited, setStopPriceWasEdited] = useState(false) // The user has edited the stop price and we don't want to change it's value automatically

  const [amountLastClickTime, setAmountLastClickTime] = useState(null) // Last time the quick amount buttons were clicked

  const [{ x, y }, setPosition] = useState({ x: 0, y: 0 }) // Used for positioning the edit instrument popup

  const { t } = useContext(I18nContext)
  const { hasAnyRole } = useContext(PermissionsContext)

  const isMarket = data.orderType === 'MARKET'

  const { colorMode } = useThemeUI()
  const handleModal = message =>
    showErrorDialog({
      title: t('error').toUpperCase(),
      type: 'ERROR',
      message: message,
    })

  const getPreferedSubscriptionType = desiredInstrument => {
    if (desiredInstrument?.allowedSubscriptions?.includes('TICK')) return 'tick'
    if (desiredInstrument?.allowedSubscriptions?.includes('TRADE')) return 'trade'
    //TODO: Not using books for now // if (desiredInstrument?.allowedSubscriptions?.includes("BOOK")) return 'book'
  }

  useEffect(() => {
    if (!instrument) {
      // console.error("Trade Panel: Instrument was not available on mount")
      return
    }
    const subscriptionType = getPreferedSubscriptionType(instrument)
    subscribeSymbolDynamic({ instrument, subscriptionType })
  }, [])

  useEffect(() => {
    setLimitPriceWasEdited(false)
    setStopPriceWasEdited(false)
    if (data.orderType === 'MARKET') {
      setPriceInputsDisabled(true)
    } else {
      setPriceInputsDisabled(false)
    }
  }, [data.selectedInstrumentId])

  const onSelectInstrument = (newInstrument, tabIndex) => {
    let newOrderType = data.orderType
    let newOrderDuration = data.orderDuration
    let newOrderExpiration = data.orderExpiration

    if (instrument && newInstrument !== instrument) {
      const subscriptionType = getPreferedSubscriptionType(instrument)
      unsubscribeSymbolDynamic({ instrument, subscriptionType })
    }

    if (newInstrument?.exchangeId !== instrument?.exchangeId) {
      newOrderType = newInstrument?.allowedOrderTypes[0]
      newOrderDuration = newInstrument?.allowedTimeInForces[newOrderType][0]
      newOrderExpiration = undefined
    }

    updateTradePanel({
      selectedInstrumentId: newInstrument?.id,
      price: 0,
      stopPrice: 0,
      orderType: newOrderType,
      orderDuration: newOrderDuration,
      newOrderExpiration: newOrderExpiration,
      tabIndex,
      status: 'VIEW',
    })
    if (newInstrument !== instrument) {
      const subscriptionType = getPreferedSubscriptionType(newInstrument)
      subscribeSymbolDynamic({ instrument: newInstrument, subscriptionType })
    }
  }

  const setAmount = value => {
    const { amount: currentAmount } = data
    const now = new Date()
    const diff = amountLastClickTime?.getTime() - now.getTime()
    updateTradePanel({
      amount: diff && Math.abs(diff) < 2000 ? value + currentAmount : value,
      tabIndex,
    })
    setAmountLastClickTime(now)
  }

  const makeOperation = () => {

    const payload = {
      side: data.action.toUpperCase(),
      price: data.price || marketData,
      stopPrice: data.stopPrice,
      orderType: data.orderType,
      expirationDate: data.expirationDate,
      quantity: data.amount,
      tif: data.orderDuration,
      clientId: data.clientId,
      decisorId: data.decisorId,
      executorId: data.executorId,
      tradingCapacity: data.tradingCapacity,
      title: t('confirmOrder'),
    }

    sendOrder({
      payload,
      currentAccount,
      instrument,
      hasAnyRole,
      t,
      isConfirmDialogRequired,
      makeOrderWithConfirm,
      makeOrder,
      keycloak,
      handleModal,
    })
  }

  const fixPrice = price =>
    renderPips(
      price === '' || price
        ? price
        : (marketData && Number(marketData).toFixed(instrument?.priceDecimals)) || 0,
      instrument
    )

  const price = fixPrice(data.price)
  const stopPrice = fixPrice(data.stopPrice)

  const priceComplete = price.bigFigure + price.pips + price.fractionalPips

  const buttonPrice = data.orderType === 'LIMIT' ? data.price : data.orderType === 'STOP_LIMIT' ? data.stopPrice : ''


  const isDisabled = () => {
    if (data?.selectedInstrumentId) {
      if (isMarket && priceComplete >= 0 && data.amount > 0) {
        return false
      } else {
        return price.error || priceComplete <= 0 || !data.amount || data.amount <= 0
      }
    }
    return true
  }

  return (
    <>
      <div sx={style.container}>
        <div sx={style.flex}>
          <CustomButton
            onClick={() => updateTradePanel({ action: 'BUY', tabIndex })}
            size="large"
            type={data.action === BUY ? BUY.toLowerCase() : 'cancel'}
            sx={{ ml: '0px' }}
            width="50%"
          >
            {t(BUYACTION)}
          </CustomButton>
          <CustomButton
            onClick={() => updateTradePanel({ action: 'SELL', tabIndex })}
            size="large"
            type={data.action === SELL ? SELL.toLowerCase() : 'cancel'}
            sx={{ mr: '0px', ml: '4px' }}
            width="50%"
          >
            {t(SELLACTION)}
          </CustomButton>
        </div>
        <div
          sx={style.instrument}
          onClick={e => {
            const { top, left } = e.target.offsetParent.getBoundingClientRect()
            setPosition({ x: left + 90, y: top + 100 })
            editTradePanel(tabIndex)
          }}
        >
          <div>{instrument?.symbol || t('selectInstrument')}</div>
          <span
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '8px',
            }}
          >
            <InputAdornment position="end">
              <p style={{ color: 'rgba(255,255,255,0.2)' }}>{instrument?.description}</p>
            </InputAdornment>
          </span>
        </div>

        <>
          <AmountSelector
            action={setAmount}
            styles={{
              height: '44px',
              fontSize: 'large',
              width: '100%',
              minwidth: '0px',
              my: '5px',
            }}
            tabIndex={tabIndex}
            updateFn={updateTradePanel}
            value={data.amount}
            endAdornmentText={t('QTY')}
          />

          {(instrument?.allowedFeatures?.includes("QUICK_QTY") || false) && (
            <div sx={style.amounts(colorMode)}>
              {AMOUNT_VALUES.map(value => (
                <button key={value} onClick={() => setAmount(value)}>
                  {value}
                </button>
              ))}
            </div>
          )}
        </>
        <Orders
          tabIndex={tabIndex}
          updateTradePanel={updateTradePanel}
          orderDuration={data.orderDuration}
          orderType={data.orderType}
          orderTypeOnChange={e => {
            updateTradePanel({
              orderType: e.target.value,
              tabIndex,
              orderDuration: data.orderDuration,
              price:
                e.target.value === 'MARKET' || e.target.value === 'STOP_LIMIT'
                  ? 0
                  : marketData || data.price,
              stopPrice:
                e.target.value === 'MARKET' || e.target.value === 'LIMIT'
                  ? 0
                  : marketData || data.stopPrice,
            })
            e.target.value === 'MARKET'
              ? setPriceInputsDisabled(true)
              : setPriceInputsDisabled(false)
          }}
          expirationDate={data.expirationDate}
          instrument={instrument}
        />
        <>
          {data.orderType === 'STOP_LIMIT' ? (
            <div sx={{ width: '100%' }}>
              <PriceSelector
                disabled={priceInputsDisabled}
                type={'stopPrice'}
                editPrice={stopPriceWasEdited}
                enableEditPrice={setStopPriceWasEdited}
                instrument={instrument}
                price={stopPrice || 0}
                tabIndex={tabIndex}
                updateTradePanel={updateTradePanel}
                endAdornmentText={t('stopPrice')}
                currentPrice={data.stopPrice}
              />
              <PriceSelector
                disabled={priceInputsDisabled}
                type={'price'}
                editPrice={limitPriceWasEdited}
                enableEditPrice={setLimitPriceWasEdited}
                instrument={instrument}
                price={price || 0}
                tabIndex={tabIndex}
                updateTradePanel={updateTradePanel}
                endAdornmentText={t('limitPrice')}
                currentPrice={data.price}
              />
            </div>
          ) : (
            <div sx={{ width: '100%' }}>
              <PriceSelector
                disabled={priceInputsDisabled}
                type={'price'}
                editPrice={limitPriceWasEdited}
                enableEditPrice={setLimitPriceWasEdited}
                instrument={instrument}
                price={price}
                tabIndex={tabIndex}
                updateTradePanel={updateTradePanel}
                endAdornmentText={t('price')}
                currentPrice={data.price}
              />
              <PriceSelector disabled={true} />
            </div>
          )}
        </>

        {(instrument?.marketHasOrk || false) && (
          <PermissionsVisible anyRoles={['member_trader']}>
            <TradingCapacity value={data} tabIndex={tabIndex} />
          </PermissionsVisible>
        )}
        <div sx={style.buttons}>
          <CustomButton
            type={!isDisabled() ? data?.action.toLowerCase() : 'disabled'}
            size="large"
            width="100%"
            onClick={() => !isDisabled() && makeOperation()}
          >
            <span>
              {t(data.action === BUY ? BUYACTION : SELLACTION)}&nbsp;
              <FormattedNumberText value={data.value} />
              &nbsp;
              {instrument?.symbol || ''}&nbsp;
              {data.orderType !== 'MARKET' && (
                < FormattedNumberText value={buttonPrice} prefix="@ " suffix=" " />
              )
              }
              {t(data.orderType)}&nbsp;
              {t(data.orderDuration)}
            </span>
          </CustomButton>
        </div>
      </div>
      {data.status === 'EDIT' && (
        <div sx={setPos([x, y])}>
          <ClickAwayListener onClickAway={() => editTradePanel(tabIndex)}>
            <InstrumentSearch
              search={instrument?.symbol || ''}
              tabIndex={tabIndex}
              onSelectInstrument={onSelectInstrument}
            />
          </ClickAwayListener>
        </div>
      )}
    </>
  )
}

const instrumentSelector = (state, ownProps) => {
  const instruments = hydratedInstrumentSelector(state, ownProps)
  const currentData = currentDataSelector(state, ownProps)
  return instruments[currentData.selectedInstrumentId]
}

const marketDataSelector = createSelector(
  instrumentSelector,
  tradeSelector,
  tickSelector,
  currentDataSelector,
  (instrument, trades, ticks, currentData) => {
    if (!instrument) {
      return 0
    }
    const accountId = instrument?.marketNeedsAccount ? instrument?.selectedAccountId || '' : ''
    const instrumentHasTicks = instrument?.allowedSubscriptions?.includes('TICK') || false
    const instrumentHasTrades = instrument?.allowedSubscriptions?.includes('TRADE') || false
    const bid = currentData.action === BUY

    let price

    if (instrumentHasTicks) {
      price = bid
        ? ticks[instrument?.exchangeId]?.[instrument?.symbol]?.[accountId]?.askPrice
        : ticks[instrument?.exchangeId]?.[instrument?.symbol]?.[accountId]?.bidPrice
    } else if (instrumentHasTrades) {
      price = trades[instrument?.exchangeId]?.[instrument?.symbol]?.[accountId]?.price
    } else {
      console.error('TradePanel: Selected instrument does not have tick nor trade subscriptions')
      price = undefined
    }

    return price || 0
  }
)

const mapStateToProps = (state, ownProps) =>
  createSelector(
    instrumentSelector,
    marketDataSelector,
    confirmDialogSelector,
    currentWorkspaceSelector,
    currentDataSelector,
    accountsSelector,
    (instrument, marketData, isConfirmDialogRequired, currentWorkspace, data, currentAccount) => ({
      instrument,
      marketData,
      isConfirmDialogRequired,
      currentWorkspace,
      data,
      currentAccount,
    })
  )(state, ownProps)

const mapDispatchToProps = {
  editTradePanel,
  updateTradePanel,
  unsubscribeSymbolDynamic,
  subscribeSymbolDynamic,
  makeOrder,
  makeOrderWithConfirm,
  connectToOrdersSocket,
  showErrorDialog,
}

export default connect(mapStateToProps, mapDispatchToProps)(TradePanel)
