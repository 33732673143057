import Api from '../common/api'
import config from '../common/config'

export const fetchWorkspace = token => dispatch => {
  const api = new Api({
    baseUrl: config().urls.baseUrl,
    defaultOptions: { headers: { Authorization: `Bearer ${token}` } },
  })
  dispatch({
    type: 'FETCH_WORKSPACE',
    payload: api.get('/preferences/v2/workspace'),
  })
}

export function saveWorkspace(payload) {
  const api = new Api({
    baseUrl: config().urls.baseUrl,
    defaultOptions: { headers: { Authorization: `Bearer ${payload.token}` } },
  })
  return {
    type: 'SAVE_WORKSPACE',
    payload: api.put('/preferences/v2/workspace', { body: payload.data }),
  }
}

export const changeWorkspace = payload => ({
  type: 'CHANGE_WORKSPACE',
  payload,
})

export const addCurrencyTab = payload => ({
  type: 'ADD_CURRENCY_TAB',
  payload,
})

export const addTableTab = payload => ({
  type: 'ADD_TABLE_TAB',
  payload,
})

export const addRfqTab = payload => ({
  type: 'ADD_RFQ_TAB',
  payload,
})

export const addTradePanel = payload => ({
  type: 'ADD_TRADE_PANEL',
  payload,
})

export const addOrderListPanel = payload => ({
  type: 'ADD_ORDER_LIST_PANEL',
  payload,
})

export const addMarketDepthPanel = payload => ({
  type: 'ADD_MARKET_DEPTH_PANEL',
  payload,
})

export const addTradeListPanel = payload => ({
  type: 'ADD_TRADE_LIST_PANEL',
  payload,
})

export const addQuoteListPanel = payload => ({
  type: 'ADD_QUOTE_LIST_PANEL',
  payload,
})

export const addPriceListPanel = payload => ({
  type: 'ADD_PRICE_LIST_PANEL',
  payload,
})

export const addPositionListPanel = payload => ({
  type: 'ADD_POSITION_LIST_PANEL',
  payload: payload,
})

export const addAccountSummaryListPanel = payload => ({
  type: 'ADD_ACCOUNT_SUMMARY_LIST_PANEL',
  payload: payload,
})

export const addAccountGroupPanel = payload => ({
  type: 'ADD_ACCOUNT_GROUP_PANEL',
  payload: payload,
})

export const updateAccountGroup = payload => ({
  type: 'UPDATE_ACCOUNT_GROUP',
  payload: payload,
})

export const deleteAccountGroup = payload => ({
  type: 'DELETE_ACCOUNT_GROUP',
  payload: payload,
})

export const addAccountGroup = payload => ({
  type: 'ADD_ACCOUNT_GROUP',
  payload: payload,
})

export const addWorkspace = payload => ({
  type: 'ADD_WORKSPACE',
  payload,
})

export const removeWorkspace = workspace => ({
  type: 'REMOVE_WORKSPACE',
  payload: workspace,
})

export const removeTab = payload => ({
  type: 'REMOVE_TAB',
  payload,
})

export const updateWorkspaceName = (index, name) => ({
  type: 'UPDATE_WORKSPACE_NAME',
  payload: { index, name },
})

export function addCurrency(instrument, tabIndex) {
  return {
    type: 'ADD_CURRENCY',
    payload: { instrument, tabIndex },
  }
}

export function deleteCurrency(spotIndex, tabIndex) {
  return {
    type: 'DELETE_CURRENCY',
    payload: { spotIndex, tabIndex },
  }
}

export function resetCurrency(spotIndex, tabIndex, deleting) {
  console.warn('Calling reset currency')
  return {
    type: 'RESET_CURRENCY',
    payload: { spotIndex, tabIndex, deleting },
  }
}

export const showConfirmDialog = payload => ({
  type: 'SHOW_CONFIRM_DIALOG',
  payload: {
    show: true,
    payload,
  },
})

export const closeConfirmDialog = () => ({
  type: 'CLOSE_CONFIRM_DIALOG',
})

export const changeToViewMode = (instrument, spotIndex, tabIndex) => ({
  type: 'CHANGE_TO_VIEW_MODE',
  payload: { instrument, spotIndex, tabIndex },
})

export const updateAmountToOperate = payload => dispatch => {
  if (payload.value < 0) {
    return
  }
  dispatch({
    type: 'UPDATE_AMOUNT_TO_OPERATE',
    payload,
  })
}

export const editTradePanel = payload => ({
  type: 'EDIT_TRADE_PANEL',
  payload,
})

export const updateTradePanel = payload => ({
  type: 'UPDATE_TRADE_PANEL',
  payload,
})

export const updateOrkData = payload => ({
  type: 'UPDATE_ORK_DATA',
  payload,
})

export const updateQuoteList = payload => ({
  type: 'UPDATE_QUOTE_LIST',
  payload,
})

export const updatePriceList = payload => ({
  type: 'UPDATE_PRICE_LIST',
  payload,
})

export const createLayout = (layout, workspaceIndex) => ({
  type: 'CREATE_LAYOUT',
  payload: { layout, workspaceIndex },
})

export const updateCurrentLayout = payload => ({
  type: 'UPDATE_CURRENT_LAYOUT',
  payload,
})

export const reorderCurrencies = payload => ({
  type: 'REORDER_CURRENCIES',
  payload,
})

export const changeTradingCapacity = payload => ({
  type: 'CHANGE_TRADING_CAPACITY',
  payload,
})

export const changeTradingOrk = payload => ({
  type: 'CHANGE_TRADING_ORK',
  payload,
})

export const changeConfirmModalRequired = () => ({
  type: 'CHANGE_CONFIRM_MODAL_REQUIRED',
})

export const showUserSettings = () => ({
  type: 'SHOW_USER_SETTINGS',
})

export const hideUserSettings = () => ({
  type: 'HIDE_USER_SETTINGS',
})

export const setFilters = payload => ({
  type: 'SET_TABLE_FILTERS',
  payload,
})

export const setAccessor = payload => ({
  type: 'SET_TABLE_ACCESSORS',
  payload,
})

export const changeCurrentAccount = payload => ({
  type: 'CHANGE_CURRENT_ACCOUNT',
  payload,
})

export const setOpenFilter = payload => ({
  type: 'SET_OPENFILTER',
  payload,
})

export const setOpenAccessor = payload => ({
  type: 'SET_OPENACCESSOR',
  payload,
})

export const saveActiveTab = (index, activeTab) => ({
  type: 'SAVE_ACTIVE_TAB',
  payload: { index, activeTab },
})

export const showErrorDialog = payload => ({
  type: 'SHOW_ERROR_DIALOG',
  payload,
})

export const closeErrorDialog = payload => ({
  type: 'CLOSE_ERROR_DIALOG',
  payload,
})

export const setLanguage = payload => ({
  type: 'SET_LANGUAGE',
  payload,
})

export const setExpandedRows = payload => ({
  type: 'SET_EXPANDED_ROWS',
  payload,
})

export const setSelectedInstruments = payload => ({
  type: 'SET_SELECTED_INSTRUMENTS',
  payload,
})

export const updateQuoteInstrumentData = payload => ({
  type: 'SET_QUOTE_INSTRUMENT_DATA',
  payload,
})
